export const getCurrentInputVal = (
  referenceClass: string,
  event: any,
  inputName: 'email' | 'password' | 'name',
): string => {
  const parentAuthWrapper = event.currentTarget.closest(referenceClass);
  if (!parentAuthWrapper) return '';
  const selectorStr = `input[name='${inputName}']`;
  const input = parentAuthWrapper.querySelector(selectorStr);
  if (!input) return '';
  return input.value;
};
