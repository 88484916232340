import React from 'react';
import {
  ModalRoot,
  ModalContainer,
  ModalHeader,
  ModalBody,
  Text,
  Link,
  ModalFooter,
  Button,
} from '@mentimeter/ragnar-ui';
import { EXPOSED_CREDENTIALS_HELP_ARTICLE_URL } from '../../clientErrorMessages';

export const ExposedCredentialsLoginError = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <ModalRoot open={open} onOpenChange={setOpen}>
      <ModalContainer size="small">
        <ModalHeader>Reset your password</ModalHeader>
        <ModalBody>
          <Text>
            Your password may have been exposed in a data breach on a site or
            app outside of Mentimeter. For your security, please select{' '}
            <Text fontWeight="semiBold">Reset my password</Text> and we&apos;ll
            send a link directly to your email.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Link
            underline={false}
            href={EXPOSED_CREDENTIALS_HELP_ARTICLE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>
          <Button href="/auth/reset_passwords" variant="primary">
            Reset my password
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};
