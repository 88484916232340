import { v4 as uuid } from 'uuid';

export const getDeviceAttributes = () => {
  return {
    isMobile: isHandheld(),
  } as const;
};

export type SplitDeviceAttributes = ReturnType<typeof getDeviceAttributes>;

const id = uuid();
export const getDeviceId = () => id;

export function isHandheld(): boolean {
  if (typeof window === 'undefined' || !window?.navigator?.userAgent)
    return false;

  return Boolean(
    window.navigator.userAgent.match(/Android/i) ||
      window.navigator.userAgent.match(/webOS/i) ||
      window.navigator.userAgent.match(/iPhone/i) ||
      window.navigator.userAgent.match(/iPod/i) ||
      window.navigator.userAgent.match(/iPad/i) ||
      window.navigator.userAgent.match(/BlackBerry/),
  );
}
