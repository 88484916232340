import { trackSocialAuth } from '../utils/authTrack';
import type {
  AuthResponseT,
  AllAuthClientConfigT,
  AllAuthClientT,
  AuthWithSocialParamsT,
} from '../types';
import { createUserApiAuthClient } from './userApi/userApiAuth';
import createSocialAuthClient from './socialAuth';

export * from '../types';

export default function createAllAuthClient({
  baseUrl,
  helloOptions,
}: AllAuthClientConfigT): AllAuthClientT {
  const userApiAuth = createUserApiAuthClient({ baseUrl });
  const socialAuth = createSocialAuthClient({ helloOptions });
  return {
    getSocialSignInState: socialAuth?.getSocialSignInState,
    socialSignOut: socialAuth?.socialSignOut,
    authWithSocial: async ({
      network,
      useRedirect,
      referral,
      utmFirst,
      utmLast,
      visitorToken,
    }: AuthWithSocialParamsT): Promise<AuthResponseT> => {
      if (!socialAuth) throw new Error('Auth is not defined');

      const socialUser = await socialAuth.authAndGetSocialUser(
        network,
        useRedirect,
      );
      const res = await userApiAuth.authWithSocialUser({
        socialUser,
        network,
        referral,
        utmFirst,
        utmLast,
        visitorToken,
      });

      trackSocialAuth(network);

      return res;
    },
    login: userApiAuth.login,
    signup: userApiAuth.signup,
    checkSignupMode: userApiAuth.checkSignupMode,
  };
}
