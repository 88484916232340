import * as React from 'react';
import type { TrackingPayload } from '@mentimeter/http-clients';
import { WorkspaceHint } from './WorkspaceHint';

export const WorkspaceHintDataHandler = ({
  track,
  signupModeInfo,
}: {
  track?: ((payload: TrackingPayload) => void) | undefined;
  signupModeInfo: {
    companyName: string;
    joinUrl: string;
    samlLoginUrl: string;
    id: number | undefined;
  };
}) => {
  const [teamPromotionClicked, setTeamPromotionClicked] =
    React.useState<boolean>(false);
  const { companyName, joinUrl, samlLoginUrl, id } = signupModeInfo;
  const showWorkspaceHint = !!samlLoginUrl && !!joinUrl;

  const handleTeamPromotionClick = (): void => {
    setTeamPromotionClicked(true);
    if (track) {
      track({
        event: 'Clicked workspace hint button',
        properties: {
          sso: !!samlLoginUrl,
          'workspace id': id,
        },
      });
    }
  };

  React.useEffect(() => {
    if (showWorkspaceHint && track) {
      track({
        event: 'Displayed workspace hint',
        properties: {
          sso: !!samlLoginUrl,
          'workspace id': id,
        },
      });
    }
  }, [showWorkspaceHint, track, id, samlLoginUrl]);

  return (
    <WorkspaceHint
      companyName={companyName}
      joinUrl={joinUrl}
      samlLoginUrl={samlLoginUrl}
      teamPromotionClicked={teamPromotionClicked}
      showWorkspaceHint={showWorkspaceHint}
      handleTeamPromotionClick={handleTeamPromotionClick}
    />
  );
};
