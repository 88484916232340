import { getLocalUser } from '@mentimeter/user';

export const getDefaultUserAttributes = () => {
  const user = getLocalUser();
  if (!user) return null;

  const plan = user.features?.type;
  const role = user.role;

  return {
    userId: user.id,
    email: user.email,
    createdAt: `${new Date(user.created_at).getTime()}`,
    workspaceId: String(user.active_workspace_id ?? 0),
    betaStatus: user.beta_status,
    region: user.region,
    ...(plan && { plan }),
    ...(role && { role }),
  } as const;
};

export type SplitDefaultUserAttributes = ReturnType<
  typeof getDefaultUserAttributes
>;
