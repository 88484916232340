import * as React from 'react';
import type { RegisterT, UnRegisterT } from './Hotkey';
import HK from './Hotkey';
import { keys as defaultKeyStrokes } from './Keys';
import type { DefaultValidKeyCodesT } from './types';
import type { ContextT } from './HotkeyContext';
import HotkeyContext from './HotkeyContext';
import { filter } from './utils';

interface DebugT {
  logNativeEvent?: boolean;
  keyCodeToDebug?: string;
  logHotkeysClass?: boolean;
  logHotkeyId?: boolean;
}

interface ProviderPropsT {
  children: React.ReactNode;
  keyStrokes?: Array<DefaultValidKeyCodesT>;
  instance: string;
  initiateHotkeys?: boolean;
  register: RegisterT;
  unregister: UnRegisterT;
  DEBUG?: DebugT;
}

export interface WrapperPropsT {
  children: React.ReactNode;
  keyStrokes?: Array<DefaultValidKeyCodesT>;
  instance?: string;
  DEBUG?: DebugT;
}

const createObject = (arr: Array<DefaultValidKeyCodesT>) =>
  arr.reduce((obj, d) => ({ ...obj, [d]: true }), {});

const Provider = ({
  children,
  keyStrokes,
  instance,
  initiateHotkeys,
  register,
  unregister,
  DEBUG,
}: ProviderPropsT) => {
  const newKeystrokes = keyStrokes
    ? createObject(keyStrokes)
    : defaultKeyStrokes;
  const [strokes] = React.useState<Record<string, boolean>>(newKeystrokes);
  const [Hotkeys] = React.useState<Partial<HK>>(
    initiateHotkeys
      ? new HK(instance, DEBUG && DEBUG.logHotkeyId)
      : ({} as Partial<HK>),
  );
  const [contextState] = React.useState<ContextT>({
    register: Hotkeys.register || register,
    unregister: Hotkeys.unregister || unregister,
    eventHistory: Hotkeys.eventHistory,
    instance,
    keyStrokes: strokes,
  });
  React.useEffect(() => {
    if (Hotkeys.register) {
      const globalHandler = (e: React.KeyboardEvent<HTMLElement>) => {
        if (filter.ignoreHotkeyModifier(e)) return;

        Hotkeys.dispatch?.(e);
        if (DEBUG) {
          if (DEBUG.logNativeEvent && !DEBUG.keyCodeToDebug) {
            // eslint-disable-next-line
            console.log(e);
          }
          if (DEBUG.keyCodeToDebug) {
            const key = Hotkeys.getKeyFromEvent?.(e);
            // eslint-disable-next-line
            console.log('Corresponding keyCode:', key, e);
          }
        }
      };
      Hotkeys.addHotkeyListeners?.(globalHandler);
      return () => Hotkeys.removeHotkeyListeners?.(globalHandler);
    }
    return () => {};
  }, [Hotkeys, DEBUG]);
  if (DEBUG && DEBUG.logHotkeysClass) {
    // eslint-disable-next-line
    console.log(Hotkeys);
  }
  return (
    <HotkeyContext.Provider value={contextState}>
      {children}
    </HotkeyContext.Provider>
  );
};

const Wrapper = ({ children, ...rest }: WrapperPropsT) => {
  return (
    <HotkeyContext.Consumer>
      {({ register, unregister, instance }: ContextT) => (
        <Provider
          initiateHotkeys={!register}
          register={register}
          unregister={unregister}
          instance={instance}
          {...rest}
        >
          {children}
        </Provider>
      )}
    </HotkeyContext.Consumer>
  );
};

export default Wrapper;
