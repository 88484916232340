export const applyQueryToPath = (newHref: string): string => {
  if (
    typeof window === 'undefined' ||
    !window ||
    !window.location ||
    !window.location.search
  ) {
    return newHref;
  }
  return `${newHref}${window.location.search}`;
};
