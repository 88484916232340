import { addBreadcrumb } from '@mentimeter/errors/sentry';
import { prefixUserIdWithUS, DEFAULT_REGION } from '@mentimeter/region';
import { SplitFactory } from '@splitsoftware/splitio';

const SPLIT_BROWSER_API_KEY = process.env.NEXT_PUBLIC_SPLIT_BROWSER_API_KEY!;

export class SplitIoClientManager {
  private static instance: SplitIoClientManager;
  private splitFactory: SplitIO.IBrowserSDK | undefined;
  private client: SplitIO.IBrowserClient | undefined;

  private constructor(userId: string, region: 'eu' | 'us' = DEFAULT_REGION) {
    const userKey = prefixUserIdWithUS({
      userId,
      region,
    });

    const config: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey: SPLIT_BROWSER_API_KEY,
        trafficType: 'user',
        key: userKey,
      },
      storage: {
        type: 'LOCALSTORAGE',
      },
    };

    this.splitFactory = SplitFactory(config);
    this.client = this.splitFactory.client();
    addBreadcrumb({
      message: 'Created SplitIO client for user',
      level: 'warning',
      data: { userId },
    });
  }

  public static getInstance(
    loggedUserId: string,
    region: 'eu' | 'us' = DEFAULT_REGION,
  ): SplitIoClientManager {
    if (!SplitIoClientManager.instance) {
      SplitIoClientManager.instance = new SplitIoClientManager(
        loggedUserId,
        region,
      );
    }

    return SplitIoClientManager.instance;
  }

  public async cleanUpClient(): Promise<void> {
    if (this.client) {
      await this.client.destroy();
      this.client = undefined;
    }

    if (this.splitFactory) {
      this.splitFactory = undefined;
    }
  }

  private async waitUntilReady(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      addBreadcrumb({
        message: 'Waiting for SplitIO client to be ready',
        level: 'warning',
      });

      if (!this.client) {
        throw new Error(
          '[SplitIoClientManager] The SplitIO client must be initialized',
        );
      }

      this.client
        .ready()
        .then(() => {
          addBreadcrumb({
            message: 'SplitIO client IS Ready',
            level: 'warning',
          });

          resolve();
        })
        .catch(() => {
          reject(
            new Error('[SplitIO SDK] The client initialization timed out'),
          );
        });
    });
  }

  public async getClient(): Promise<SplitIO.IBrowserClient> {
    if (!this.client) {
      throw new Error(
        '[SplitIoClientManager] The SplitIO client must be initialized',
      );
    }

    await this.waitUntilReady();

    return this.client;
  }
}
