import React from 'react';
import { Heading, Box, Text } from '@mentimeter/ragnar-ui';
import { useTranslations, withMentimeterI18nProvider } from '@mentimeter/i18n';
import type { TrackingPayload } from '@mentimeter/http-clients';
import { getVisitorToken } from '@mentimeter/splitio';
import { getUtmValues } from '@mentimeter/utm';
import type AuthPackageT from '../../AuthPackage';
import { useAuthHandlers } from '../../useAuthHandlers';
import StrictSSOInfo from './StrictSSOInfo';
import SignUpForm from './SignUpForm';
import Terms from './Terms';
import { Divider } from './Divider';
import { SocialAuthWrapper } from './SocialAuthWrapper';
import { AuthErrorMessage } from './AuthErrorMessage';

export interface PropsT {
  authPackage: AuthPackageT;
  SSOChanged?: (arg0: any) => any;
  initialEmail?: string | undefined;
  initialPassword?: string | undefined;
  initialName?: string | undefined;
  continueUrl?: string | undefined;
  referral: string;
  useSocialRedirect?: boolean | undefined;
  emailChangeDisabled?: boolean | undefined;
  track: ((payload: TrackingPayload) => void) | undefined;
  bg?: string;
  borderRadius?: string;
  padding?: string;
  maxWidth?: string;
  showEmailAutoSuggest?: boolean | undefined;
  [key: string]: unknown;
  inviteToken?: string | undefined;
}

const SignupWrapper = ({
  referral,
  authPackage,
  SSOChanged,
  initialEmail,
  initialPassword,
  initialName,
  emailChangeDisabled = false,
  continueUrl = '/app',
  useSocialRedirect,
  track,
  bg,
  borderRadius,
  padding,
  maxWidth,
  showEmailAutoSuggest,
  inviteToken,
}: PropsT) => {
  const {
    components: { Signup: SignUpAuth },
  } = authPackage;
  const { utmFirst, utmLast } = getUtmValues();
  const visitorToken = getVisitorToken();
  const {
    onError,
    error,
    onSocialAuthSuccess,
    onSignupSuccess,
    isLoading,
    setLoading,
    disabled,
    SSOInfo,
    resetAuth,
  } = useAuthHandlers({
    onSSOChange: SSOChanged,
    continueUrl,
    referral,
  });
  const {
    loginUrl,
    companyName,
    companyContactEmail,
    companyContactUrl,
    companyContactName,
  } = SSOInfo;

  const t = useTranslations('authPackage');
  if (SSOInfo.type === 'strict') {
    return (
      <StrictSSOInfo
        resetForm={resetAuth}
        companyName={companyName}
        companyContactEmail={companyContactEmail}
        companyContactUrl={companyContactUrl}
        companyContactName={companyContactName}
        loginUrl={loginUrl}
      />
    );
  }
  return (
    <>
      <Box
        bg={bg}
        borderRadius={borderRadius}
        p={padding}
        width="100%"
        alignItems="stretch"
        maxWidth={maxWidth ?? '360px'}
        mx="auto"
      >
        <Heading
          as="h2"
          fontWeight="semiBold"
          textAlign="center"
          fontSize={3}
          mb={3}
        >
          {t('create_free_account')}
        </Heading>
        <Text mb="space4">{t('work_email_promote')}</Text>
        <SocialAuthWrapper
          visitorToken={visitorToken}
          onError={onError}
          setLoading={setLoading}
          onSuccess={onSocialAuthSuccess}
          utmFirst={utmFirst}
          utmLast={utmLast}
          disabled={disabled}
          referral={referral}
          isSignup={true}
          useRedirect={Boolean(useSocialRedirect)}
          authPackage={authPackage}
        />
        <Box maxWidth="360px" width="100%" alignItems="stretch" mx="auto"></Box>
        <Divider />
        <SignUpAuth
          onSuccess={onSignupSuccess}
          onError={onError}
          setLoading={setLoading}
          utmFirst={utmFirst}
          utmLast={utmLast}
          referral={referral}
          visitorToken={visitorToken}
          inviteToken={inviteToken}
          render={({
            handleSubmit,
            handleEmailBlur,
            handleEmailChange,
            signupModeInfo,
          }) => (
            <SignUpForm
              onSubmit={handleSubmit}
              onEmailBlur={handleEmailBlur}
              onEmailChange={handleEmailChange}
              signupModeInfo={signupModeInfo}
              isLoading={isLoading}
              disabled={disabled}
              initialEmail={initialEmail}
              initialPassword={initialPassword}
              initialName={initialName}
              emailChangeDisabled={emailChangeDisabled}
              track={track}
              showEmailAutoSuggest={showEmailAutoSuggest}
            />
          )}
        />
        {error ? (
          <AuthErrorMessage error={error} continueUrl={continueUrl} mb={2} />
        ) : null}
        {!bg && <Terms />}
      </Box>
      {bg && (
        <Box mx="auto" py={2}>
          <Terms />
        </Box>
      )}
    </>
  );
};

export default withMentimeterI18nProvider(SignupWrapper);
