// NB: This is a very important security check! Otherwise you can
// do trivial reflected XSS attacks via the following, for example:
// ?continue=javascript://alert(document.cookie)
/**
 * Accepts a uri and returns it if it's safe to redirect to or the defaultUrl if not.
 * A url is considered safe if:
 * - has same origin as site you're visiting
 * - is local to the current domain.
 *
 * @param uri
 * @param defaultUrl
 * @returns
 */
export const isSafeUrl = (
  uri: string | Array<string> | null | undefined,
): uri is string => {
  if (typeof uri !== 'string') {
    return false;
  }

  // Do not allow url:s with CRLF chars
  if (uri.match(/[\n\r]/g) !== null) {
    return false;
  }

  const url = uri.trim();

  // Allow redirect to same origin if url starts with http(s)://
  if (
    url.match(/^https?:\/\//) &&
    url.startsWith(`${window.location.origin}/`)
  ) {
    return true;
  }

  // Allow redirect to local url
  return url === '/' || url.match(/^\/[a-zA-Z?]/) !== null;
};

export const getSafeUrl = (
  uri: string | Array<string> | null | undefined,
  defaultUrl: string,
): string => {
  return isSafeUrl(uri) ? uri : defaultUrl;
};

export const redirect = (defaultUrl: string | null, timeout = true) => {
  const redirect = () => {
    const queryUrl = new URL(window.location.href).searchParams.get('continue');
    const redirectUrl = getSafeUrl(queryUrl, defaultUrl || '');
    window.location.assign(redirectUrl);
  };

  if (timeout) {
    setTimeout(redirect, 300);
  } else {
    redirect();
  }
};
