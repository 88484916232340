import type { FormikHelpers } from 'formik';
import type { VisitorToken } from '@mentimeter/splitio';
import type { AllAuthClientT, AuthResponseT, OnErrorFnT } from '../types';

interface SubmitValuesT {
  email: string;
  password: string;
}

type HandleSubmitT = (
  values: SubmitValuesT,
  formikActions: FormikHelpers<SubmitValuesT>,
) => any;

interface RenderPropsT {
  handleSubmit: HandleSubmitT;
}

export interface PropsT {
  render: (arg0: RenderPropsT) => any;
  onSuccess: (arg0: AuthResponseT) => any;
  onError: OnErrorFnT;
  setLoading: (isLoading: boolean) => void;
  visitorToken: VisitorToken;
}

function createLogin(authClient: AllAuthClientT): React.FC<PropsT> {
  const Login = ({
    onSuccess,
    onError,
    render,
    setLoading,
    visitorToken,
  }: PropsT) => {
    return render({
      handleSubmit: ({ email, password }, { setSubmitting }) => {
        setLoading(true);

        authClient
          .login(email, password, visitorToken)
          .then((res) => {
            setSubmitting(false);
            onSuccess(res);
          })
          .catch((e) => {
            setSubmitting(false);
            onError(e);
          });
      },
    });
  };
  return Login;
}

export default createLogin;
