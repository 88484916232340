export const CODE_EXPOSED_CREDENTIALS = 'exposed_credentials';
export const CODE_PASSWORD_INVALID_LENGTH = 'password_invalid_length';
export const CODE_PASSWORD_ATTEMPT_DENIED = 'password_attempt_denied';
export const CODE_INVALID_CREDENTIALS = 'invalid_credentials';
export const CODE_SOCIAL_LOGIN_ENABLED = 'social_login_enabled';
export const CODE_SAML_LOGIN_ENABLED = 'saml_login_enabled';
export const CODE_SUSPENDED_USER = 'suspended_user';
export const CODE_NO_USER_FOUND = 'no_user_found';
export const CODE_NOT_FOUND = 'not_found';
export const CODE_RESTRICTED_DOMAIN = 'restricted_domain';
export const CODE_EMAIL_NOT_UNIQUE = 'not_unique_email';
export const CODE_NOT_REGISTERED_EMAIL = 'not_registered_email';
export const CODE_SAML_CANNOT_CHANGE_PASSWORD = 'saml_cannot_change_password';
export const CODE_CANNOT_CHANGE_PASSWORD = 'cannot_change_password';
