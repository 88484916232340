import { keys } from './Keys';
import type { DefaultValidKeyCodesT } from './types';
import { History } from './history';

export type KeyEventT = React.KeyboardEvent<HTMLElement>;
export type HandlerT = (e: KeyEventT) => void;
export type EventTypesT = 'keyup' | 'keydown' | 'keypress';
type DetailT = 'altKey' | 'ctrlKey' | 'metaKey' | 'shiftKey';

const KEY_MAP: Record<string, string> = {
  ' ': 'space',
};

const DocumentationLink = 'See documentation in the README to find out more';

export type RegisterT = (
  key: string,
  handler: HandlerT,
  eventType: EventTypesT,
) => void;
export type UnRegisterT = (
  key: string,
  handlerRef: HandlerT,
  eventType: EventTypesT,
) => void;

// Decalring event listenser for window object
declare interface WindowT {
  addEventListener: (key: EventTypesT, handler: HandlerT) => void;
  removeEventListener: (key: EventTypesT, Handler?: HandlerT) => void;
}
declare const window: WindowT;

const mods: Array<DetailT> = ['altKey', 'ctrlKey', 'metaKey', 'shiftKey'];
const modifierMap = {
  altKey: 'alt',
  ctrlKey: 'ctrl',
  metaKey: 'meta',
  shiftKey: 'shift',
};

interface EventKeysT {
  [key: string]: Array<HandlerT>;
}
type CallstacksT = Record<EventTypesT, EventKeysT>;

class Hotkeys {
  constructor(id: string, DEBUG?: boolean) {
    if (DEBUG) {
      // eslint-disable-next-line
      console.log(id);
    }
    this.instance = id;
  }
  eventHistory = new History<DefaultValidKeyCodesT>(120);
  instance = '';
  private callstacks: CallstacksT = {
    keyup: {},
    keydown: {},
    keypress: {},
  };
  addHotkeyListeners = (globalHandler: (e: KeyEventT) => void) => {
    window.addEventListener('keydown', globalHandler);
    window.addEventListener('keyup', globalHandler);
    window.addEventListener('keypress', globalHandler);
  };
  removeHotkeyListeners = (globalHandler: (e: KeyEventT) => void) => {
    window.removeEventListener('keydown', globalHandler);
    window.removeEventListener('keyup', globalHandler);
    window.removeEventListener('keypress', globalHandler);
  };

  register = (key: string, handler: HandlerT, eventType: EventTypesT) => {
    this.callstacks[eventType][key] ||= [];
    this.callstacks[eventType][key]!.push(handler);
  };

  unregister = (key: string, handlerRef: HandlerT, eventType: EventTypesT) => {
    const callstack = this.callstacks[eventType][key];
    if (callstack !== undefined && callstack.length > 0) {
      const lastIndex = callstack.length - 1;
      for (let index = lastIndex; index >= 0; index--) {
        const callRef = callstack[index];
        if (handlerRef === callRef) {
          this.callstacks[eventType][key]?.splice(index, 1);
          break;
        }
      }
    }
  };

  getKeyFromEvent = (e: KeyEventT) => {
    const identifier = e.type === 'keypress' ? e.charCode : e.keyCode;
    const k =
      e.key ||
      browserAlternativeKeys[identifier] ||
      String.fromCharCode(e.which);
    const keyCode = KEY_MAP[k.toLowerCase()] || k.toLowerCase();
    const keyArray = [browserAlternativeKeys[keyCode] || keyCode];
    mods.forEach((d) => {
      const property: boolean = e[d];
      const modifier = modifierMap[d];
      if (property && keyCode !== modifier) {
        keyArray.push(modifier);
      }
    });
    const key = keyArray
      .toString()
      .replace(/,/g, '+')
      .toLowerCase() as DefaultValidKeyCodesT;
    return key;
  };

  dispatch = (e: KeyEventT) => {
    const { type: eventType } = e as { type: EventTypesT };
    const callstacks = this.callstacks[eventType];
    const key = this.getKeyFromEvent(e);
    this.eventHistory.add(key);
    const callstack = callstacks[key];
    if (callstack && callstack[callstack.length - 1]) {
      const handler = callstack[callstack.length - 1];
      handler?.(e);
    }
  };
}

export const validateKey = (
  key: string,
  declaredKeys: {
    [key: string]: boolean;
  } = keys,
  identifier: string,
): boolean => {
  const keyExists = Boolean(declaredKeys[key]);
  if (!keyExists) {
    const instance = `\r\n Instance: "${identifier ? identifier : ''}"`;
    const badKeystrokesMessage = `The keyCode passed to HotkeyHandler was not recognized: [${key
      .toString()
      .replace(/,/g, ', ')}] \r\n  ${`Supported keystrokes: [${Object.keys(
      declaredKeys,
    )
      .toString()
      .replace(/,/g, ', ')}]. \r\n  ${DocumentationLink}. ${instance} `}`;
    console.error(badKeystrokesMessage); // eslint-disable-line
  }
  return keyExists;
};

const browserAlternativeKeys: Record<number | string, string> = {
  esc: 'escape',
  return: 'enter',
  left: 'arrowleft',
  right: 'arrowright',
  up: 'arrowup',
  down: 'arrowdown',
  del: 'delete',
  37: 'arrowleft',
  38: 'arrowup',
  39: 'arrowright',
  40: 'arrowdown',
  32: ' ',
};

export default Hotkeys;
