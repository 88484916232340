import type { AzureADBody, RegionResponse } from '@mentimeter/http-clients';
import { hash } from './hash';
import { getDomain } from './get-domain';

export enum Errors {
  FAILED_FETCHING = 'Region could not be fetched',
  TRANSFER_IN_PROGRESS = 'Transfer in progress',
}

export enum MicrosoftTeamsAuthErrors {
  INVALID_GRANT = 'User has not granted consent to the requested scope',
}

/**
 * A plain fetching function for getting the region for a given user.
 * Hashes the email with md5 using crypto-js before sending the request.
 *
 * Use with react-query or swr to get react state and retry logic out of the box.
 * ```ts
 * const query = useSwr(regionServiceUrl, () => getRegion(email, regionServiceUrl));
 * ```
 *
 * @param email - Email of the current user.
 * @param baseUrl - Base url to the api
 * @throws An error if the request fails.
 * @returns A promise with the `RegionResponse` interface
 */
export const fetchRegion = async (email: string, baseUrl: string) => {
  const lowercasedEmail = email.toLowerCase();
  const emailHash = hash(lowercasedEmail);
  const domain = getDomain(lowercasedEmail);

  const requestUrl = `${baseUrl}/region/${emailHash}?domain=${domain}`;
  const response = await fetch(requestUrl);

  if (!response.ok) {
    switch (response.status) {
      case 409: {
        throw new Error(Errors.TRANSFER_IN_PROGRESS, {
          cause: Error(String(response)),
        });
      }
      default: {
        throw new Error(Errors.FAILED_FETCHING, {
          cause: Error(String(response)),
        });
      }
    }
  }

  const data: RegionResponse = await response.json();

  return data;
};

export const fetchRegionByWorkspaceUrl = async (
  workspaceUrl: string,
  baseUrl: string,
) => {
  const workspaceHash = hash(workspaceUrl);

  const response = await fetch(`${baseUrl}/region/workspaces/${workspaceHash}`);

  if (!response.ok) {
    throw new Error(Errors.FAILED_FETCHING, {
      cause: Error(String(response)),
    });
  }

  const data: RegionResponse = await response.json();
  return data;
};

export const fetchRegionByTeamsAuthToken = async (
  authData: AzureADBody,
  baseUrl: string,
) => {
  const response = await fetch(
    `${baseUrl}/integrations/msteams/region?token=${authData.token}&tid=${authData.tid}`,
  );

  if (!response.ok) {
    const data = await response.json();
    if (data.code === 'invalid_grant') {
      throw new Error(MicrosoftTeamsAuthErrors.INVALID_GRANT, {
        cause: Error(String(response)),
      });
    }
    throw new Error(Errors.FAILED_FETCHING, {
      cause: Error(String(response)),
    });
  }

  const data: RegionResponse = await response.json();
  return data;
};
