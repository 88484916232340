import type { ComponentType as ComponentT } from 'react';
import type { AllAuthClientConfigT, AllAuthClientT } from './types';
import { createSocialAuth, createLogin, createSignup } from './components';
import type { PropsT as LoginPropsT } from './components/Login';
import type { PropsT as SignupPropsT } from './components/Signup';
import type { PropsT as SocialAuthPropsT } from './components/SocialAuth';
import createAllAuthClient from './clients/allAuth';

export default class AuthPackage {
  constructor({ baseUrl, helloOptions }: AllAuthClientConfigT) {
    const authClient = createAllAuthClient({ baseUrl, helloOptions });
    const SocialAuth = createSocialAuth(authClient);
    const Login = createLogin(authClient);
    const Signup = createSignup(authClient);

    this.components = {
      SocialAuth,
      Login,
      Signup,
    };
    this.clients = { authClient };
  }
  components: {
    SocialAuth: ComponentT<SocialAuthPropsT>;
    Login: ComponentT<LoginPropsT>;
    Signup: ComponentT<SignupPropsT>;
  };
  clients: {
    authClient: AllAuthClientT;
  };
}
