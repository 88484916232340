import * as React from 'react';
import { Button, Box, Modal } from '@mentimeter/ragnar-ui';
import { withMentimeterI18nProvider } from '@mentimeter/i18n';
import type AuthPackageT from '../../AuthPackage';
import type { SSOType } from '../../types';
import AuthModalHeader from './AuthModalHeader';
import AuthSwitch from './AuthSwitch';

interface PropsT {
  show: boolean;
  onDismiss: () => void;
  showSignup: boolean;
  authPackage: AuthPackageT;
  continueUrl: string | undefined;
  referral: string;
  useSocialRedirect?: boolean;
}

interface StateT {
  showSignup: boolean;
  show: boolean;
  SSOType: SSOType;
}

const getInitialState = (): StateT => {
  return {
    showSignup: true,
    show: false,
    SSOType: 'none',
  };
};

class AuthModal extends React.Component<PropsT, StateT> {
  override state = getInitialState();
  static getDerivedStateFromProps = (
    { show: propsShow, showSignup: propsShowSignup }: PropsT,
    { show: stateShow, showSignup: stateShowSignup }: StateT,
  ) => {
    let newState: Partial<StateT> = {
      show: propsShow,
    };
    // if modal goes from closed to open - make sure the property showSignup is ruling
    if (propsShow && !stateShow) {
      newState = { ...newState, showSignup: propsShowSignup };
    }
    // if modal goes from open to close - reset modal state
    if (!propsShow && stateShow) {
      return {
        ...getInitialState(),
        ...newState,
        // don't change state of showSignup on close, it will switch before modal has been closed
        // and look weird:
        showSignup: stateShowSignup,
      };
    }
    return newState;
  };
  override render() {
    const { showSignup, show } = this.state;
    const { referral, authPackage, onDismiss, continueUrl, useSocialRedirect } =
      this.props;
    return (
      <Modal
        data-testid="auth-modal"
        show={show}
        onDismiss={onDismiss}
        width={['100vw', '400px']}
      >
        <Box width="100%" className="authWrapper" alignItems="center">
          <AuthSwitch
            authPackage={authPackage}
            showSignup={showSignup}
            continueUrl={continueUrl}
            referral={referral}
            useSocialRedirect={Boolean(useSocialRedirect)}
            render={({
              showSignup,
              renderAuth,
              toggleAuthMode,
              SSOMessageActive,
            }) => {
              return (
                <>
                  {!SSOMessageActive && (
                    <AuthModalHeader
                      showSignup={showSignup}
                      setAuthMode={toggleAuthMode}
                    />
                  )}
                  {renderAuth()}
                  {!SSOMessageActive && showSignup && (
                    <Button
                      variant="subtle"
                      href={`/auth/saml${
                        continueUrl ? `?continue=${continueUrl}` : ''
                      }`}
                      mt={2}
                      size="compact"
                    >
                      Sign up with SSO
                    </Button>
                  )}
                </>
              );
            }}
          />
        </Box>
      </Modal>
    );
  }
}

export default withMentimeterI18nProvider(AuthModal);
