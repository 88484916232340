import { AuthModalClicker as Clicker } from '@mentimeter/auth';
import authPackage from '../authPackage';

export interface RenderPropsParamT {
  onClick: () => void;
}

interface PropsT {
  showSignup: boolean;
  continueUrl?: string;
  referral: string;
  render: (arg0: RenderPropsParamT) => any;
}

const AuthModalClicker = ({
  showSignup,
  render,
  continueUrl,
  referral,
}: PropsT) => (
  <Clicker
    showSignup={showSignup}
    continueUrl={continueUrl}
    referral={referral}
    authPackage={authPackage}
    render={render}
  />
);

export default AuthModalClicker;
