import { captureException, MentiError } from '@mentimeter/errors/sentry';
import { useSplits, SplitIoClientManager } from '@mentimeter/splitio';
import type { UserResponseT } from '@mentimeter/http-clients';

type OnboardingRoute = '/app/onboarding' | '/app/new?onboarding';

const DEFAULT_PATH = '/app/new?onboarding';

const getOnboardingPath = (isTreatmentOn: boolean): OnboardingRoute =>
  isTreatmentOn ? '/app/onboarding' : DEFAULT_PATH;

type FetchTreatmentFn = (
  internalUserId: UserResponseT,
) => Promise<{ isTreatmentOn: boolean; onboardingPath: OnboardingRoute }>;

const fetchOnboardingTreatmentWithLoggedInUser: FetchTreatmentFn = async (
  loggedInUser,
) => {
  try {
    const userId = Number(loggedInUser.user_id).toString();
    const splitIoClientManager = SplitIoClientManager.getInstance(
      userId,
      loggedInUser.region,
    );
    const splitIoClient = await splitIoClientManager.getClient();

    // Note, these properties are used at treatment assignment
    // email is needed for the e2e tests,
    // and created_at for filtering the experiment.
    splitIoClient.setAttribute('email', loggedInUser.email);
    splitIoClient.setAttribute('created_at', loggedInUser.created_at);

    const treatment = splitIoClient.getTreatment('USE_Onboarding_New_Page');

    const isTreatmentOn = treatment === 'on';
    const onboardingPath = getOnboardingPath(isTreatmentOn);

    const result = { isTreatmentOn, onboardingPath };

    await splitIoClientManager.cleanUpClient();

    return result;
  } catch (splitError: any) {
    captureException(
      new MentiError(splitError?.message, {
        feature: 'onboarding-setup',
        cause: { splitError },
      }),
    );
  }
  return { onboardingPath: DEFAULT_PATH, isTreatmentOn: false };
};

export const useOnboardingPageRouting = (): {
  isReady: boolean;
  onboardingPath: OnboardingRoute;
  fetchOnboardingTreatmentWithLoggedInUser: FetchTreatmentFn;
} => {
  const { isReady, USE_Onboarding_New_Page } = useSplits([
    'USE_Onboarding_New_Page',
  ]);

  const onboardingPath = getOnboardingPath(USE_Onboarding_New_Page === 'on');

  return {
    isReady,
    onboardingPath,
    fetchOnboardingTreatmentWithLoggedInUser,
  };
};
