export class History<Items> {
  private readonly maxItems: number = 25;
  private cleanupTime: number;
  private cleanupRef: NodeJS.Timeout | undefined;

  constructor(cleanupTime: number) {
    this.cleanupTime = cleanupTime;
  }

  private cleanup = () => {
    if (this.cleanupRef) {
      clearTimeout(this.cleanupRef);
    }
    this.cleanupRef = setTimeout(() => this.clear(), this.cleanupTime);
  };

  private clear = () => (this.stack = []);

  public stack: Items[] = [];

  public add = (item: Items): Items[] => {
    this.stack = [item, ...this.stack.slice(0, this.maxItems - 1)];
    this.cleanup();
    return this.stack;
  };
}
