import * as React from 'react';
import type AuthPackageT from '../AuthPackage';
import AuthModal from './ui/AuthModal';

interface RenderPropsParamT {
  onClick: () => void;
}
interface PropsT {
  showSignup: boolean;
  continueUrl?: string | undefined;
  referral: string;
  authPackage: AuthPackageT;
  render: (arg0: RenderPropsParamT) => React.ReactNode;
}

interface StateT {
  show: boolean;
  showSignup: boolean;
}
class AuthModalClicker extends React.Component<PropsT, StateT> {
  override state = {
    show: false,
    showSignup: true,
  };
  openModal = () => {
    const { showSignup: propsShowSignup } = this.props;
    this.setState({ show: true, showSignup: propsShowSignup });
  };
  closeModal = () => {
    this.setState({ show: false });
  };

  override render() {
    const { show, showSignup } = this.state;
    const {
      authPackage,
      render: renderProps,
      continueUrl,
      referral,
    } = this.props;
    return (
      <>
        <AuthModal
          show={show}
          showSignup={showSignup}
          continueUrl={continueUrl}
          referral={referral}
          onDismiss={this.closeModal}
          authPackage={authPackage}
        />
        {renderProps({
          onClick: this.openModal,
        })}
      </>
    );
  }
}

export default AuthModalClicker;
