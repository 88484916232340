export const modifiers = {
  alt: true,
  ctrl: true,
  meta: true,
  shift: true,
};

export const numbers = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  0: true,
};

export const alphabet = {
  a: true,
  b: true,
  c: true,
  d: true,
  e: true,
  f: true,
  g: true,
  h: true,
  i: true,
  j: true,
  k: true,
  l: true,
  m: true,
  n: true,
  o: true,
  p: true,
  q: true,
  r: true,
  s: true,
  t: true,
  u: true,
  v: true,
  w: true,
  x: true,
  y: true,
  z: true,
};

export const actions = {
  backspace: true,
  tab: true,
  clear: true,
  enter: true,
  return: true,
  escape: true,
  space: true,
  arrowleft: true,
  arrowup: true,
  arrowright: true,
  arrowdown: true,
  delete: true,
  insert: true,
  home: true,
  end: true,
  pageup: true,
  pagedown: true,
  capslock: true,
};

export const extra = {
  '!': true,
  '#': true,
  '€': true,
  '%': true,
  '&': true,
  '@': true,
  '(': true,
  ')': true,
  '?': true,
  '+': true,
  '*': true,
  '^': true,
  '⇪': true,
  ',': true,
  '.': true,
  ':': true,
  '/': true,
  _: true,
  '-': true,
  '=': true,
  ';': true,
  '[': true,
  ']': true,
  '•': true,
  Ω: true,
  é: true,
  '®': true,
  '†': true,
  µ: true,
  ü: true,
  ı: true,
  œ: true,
  π: true,
  '˙': true,
  '': true,
  ß: true,
  '∂': true,
  ƒ: true,
  '¸': true,
  '˛': true,
  '√': true,
  ª: true,
  ﬁ: true,
  ø: true,
  æ: true,
  '≈': true,
  ç: true,
  '‹': true,
  '›': true,
  '≤': true,
  '‚': true,
  '…': true,
  '~': true,
  '™': true,
};

export const keys = {
  ...modifiers,
  ...numbers,
  ...alphabet,
  ...actions,
};
