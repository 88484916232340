import * as React from 'react';
import { setUserLocalStorageAndCookies } from '@mentimeter/user';
import { useOnboardingPageRouting } from '@mentimeter/onboarding-hooks';
import { trackSignup } from './utils/authTrack';
import { redirect } from './utils/redirect';
import { CODE_RESTRICTED_DOMAIN } from './clients/userApi/serverErrors';
import type { AuthResponseT, SSOInfoT, OnErrorFnT } from './types';
import {
  extractMetaData,
  type ClientAuthError,
  type ServerAuthError,
} from './clients/userApi/errors';

interface UseAuth {
  onSocialAuthSuccess: (arg0: AuthResponseT) => any;
  onLoginSuccess: (arg0: AuthResponseT) => any;
  onSignupSuccess: (arg0: AuthResponseT) => any;
  onError: OnErrorFnT;
  error: ClientAuthError | ServerAuthError | null;
  disabled: boolean;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
  SSOInfo: SSOInfoT;
  resetAuth: () => void;
}

export interface PropsT {
  continueUrl: string;
  referral: string;
  experiments?: string | undefined;
  onSSOChange?: ((arg0: SSOInfoT) => any) | undefined;
}

const initialSSOInfo = {
  type: 'none' as const,
  companyName: '',
  loginUrl: '',
  companyContactEmail: '',
  companyContactUrl: '',
  companyContactName: '',
};

const DEFAULT_CONTINUE_URL = '/app';

export const useAuthHandlers = ({
  onSSOChange,
  experiments,
  referral,
  continueUrl = DEFAULT_CONTINUE_URL,
}: PropsT): UseAuth => {
  const [error, setError] = React.useState<
    ClientAuthError | ServerAuthError | null
  >(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [SSOInfo, setSSOInfo] = React.useState<SSOInfoT>(initialSSOInfo);
  const { fetchOnboardingTreatmentWithLoggedInUser } =
    useOnboardingPageRouting();

  const handleSignupSuccess = async ({ mentiUser }: AuthResponseT) => {
    const onboardingExperiment =
      await fetchOnboardingTreatmentWithLoggedInUser(mentiUser);

    const experimentsUrl = experiments ? `experiments=${experiments}` : '';
    await setUserLocalStorageAndCookies(mentiUser);
    await trackSignup(referral, mentiUser.email);
    const redirectUrl =
      continueUrl === DEFAULT_CONTINUE_URL
        ? `${onboardingExperiment.onboardingPath}${experimentsUrl ? `&${experimentsUrl}` : ''}`
        : `${continueUrl}${experimentsUrl ? `?${experimentsUrl}` : ''}`;
    setIsLoading(false);

    redirect(redirectUrl, true);
  };

  const handleSetLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
    if (isLoading) {
      setError(null);
      setDisabled(true);
    }
  };
  const handleLoginSuccess = async ({ mentiUser }: AuthResponseT) => {
    await setUserLocalStorageAndCookies(mentiUser);
    setIsLoading(isLoading);
    redirect(continueUrl, true);
  };
  const handleSocialAuthSuccess = ({ mentiUser, isNewUser }: AuthResponseT) => {
    if (isNewUser) handleSignupSuccess({ mentiUser, isNewUser });
    handleLoginSuccess({ mentiUser, isNewUser });
  };
  const resetAuth = () => {
    setError(null);
    setIsLoading(false);
    setDisabled(false);
    setSSOInfo(initialSSOInfo);
    if (onSSOChange) onSSOChange(initialSSOInfo);
  };
  const parseAndSetErrorMsg = (
    err: ClientAuthError | ServerAuthError,
  ): void => {
    setIsLoading(false);
    setDisabled(false);
    setError(err);

    const metaData = extractMetaData(err);
    if (
      err.code === CODE_RESTRICTED_DOMAIN &&
      metaData &&
      metaData.companyName &&
      metaData.loginUrl
    ) {
      const {
        loginUrl,
        companyName,
        companyContactEmail,
        companyContactUrl,
        companyContactName,
      } = metaData;
      const newSSOState = {
        type: 'strict' as const,
        companyName,
        loginUrl,
        companyContactEmail,
        companyContactUrl,
        companyContactName,
      };
      if (onSSOChange) {
        onSSOChange(newSSOState);
      }
      setSSOInfo(newSSOState);
    }
  };

  return {
    onSocialAuthSuccess: handleSocialAuthSuccess,
    onLoginSuccess: handleLoginSuccess,
    onSignupSuccess: handleSignupSuccess,
    onError: parseAndSetErrorMsg,
    error,
    disabled,
    isLoading,
    setLoading: handleSetLoading,
    SSOInfo,
    resetAuth,
  };
};
