import React from 'react';

export type SplitFeatureOverrides = Record<string, string>;

export interface OverrideContextT {
  values: SplitFeatureOverrides;
  set: (splitName: string, value: string) => void;
  reset: (splitName: string) => void;
  clearAll: () => void;
  fromTreatments: <T extends Record<string, string>>(treatments: T) => T;
}

const noop = () => {};

const defaultOverrideContext: OverrideContextT = {
  values: {},
  set: noop,
  reset: noop,
  clearAll: noop,
  fromTreatments: ((t) => t) as OverrideContextT['fromTreatments'],
};

export const SplitOverridesContext = React.createContext<OverrideContextT>(
  defaultOverrideContext,
);

SplitOverridesContext.displayName = 'SplitOverridesContext';

export const useSplitOverrides = () => React.useContext(SplitOverridesContext);
