import * as React from 'react';
import { TextInput, type TextInputProps } from '@mentimeter/ragnar-form';
import { useTranslations } from '@mentimeter/i18n';
import emailSpellChecker from '@zootools/email-spell-checker';
import { useField } from 'formik';
import { useCallback, useEffect, useState, type FocusEvent } from 'react';

export const BaseEmailInput = (props: TextInputProps) => {
  const t = useTranslations('authPackage');
  const { name, hintText, onBlur } = props;
  const [field] = useField(name);
  const [suggestion, setSuggestion] = useState<string>('');

  useEffect(() => {
    setSuggestion('');
  }, [field.value]);

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const email = emailSpellChecker.run({
        email: field.value,
      });

      if (!email) {
        onBlur?.(e);
        return;
      }

      setSuggestion(email.full);
      onBlur?.(e);
    },
    [field.value, onBlur],
  );

  return (
    <TextInput
      {...props}
      onBlur={handleBlur}
      hintText={
        suggestion ? t('form_email_spellcheck', { suggestion }) : hintText
      }
    />
  );
};
