import * as React from 'react';
import type { DefaultValidKeyCodesT } from './types';
import type { RegisterT, UnRegisterT } from './Hotkey';
import type { History } from './history';

export interface ContextT {
  register: RegisterT;
  unregister: UnRegisterT;
  eventHistory: History<DefaultValidKeyCodesT> | undefined;
  instance: string;
  keyStrokes: {
    [key: string]: boolean;
  };
}

const HotkeyContext = React.createContext<ContextT>({} as ContextT);
export default HotkeyContext;
