/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from 'react';
import type { ContextT } from './HotkeyContext';
import HotkeyContext from './HotkeyContext';
import type { History } from './history';
import type { DefaultValidKeyCodesT } from './types';
import type { HandlerT, RegisterT, UnRegisterT } from './Hotkey';
import { validateKey } from './Hotkey';

interface DebugT {
  onRegister: (keyCode: string) => void;
  onUnRegister: (keyCode: string) => void;
}
interface PropsT {
  children?: React.ReactNode;
  conflictingKeys: DefaultValidKeyCodesT[] | undefined;
  keyCode: string;
  instance: string;
  onKeyDown?: HandlerT | undefined;
  onKeyUp?: HandlerT;
  onKeyPress?: HandlerT;
  register: RegisterT;
  eventHistory: History<DefaultValidKeyCodesT> | undefined;
  unregister: UnRegisterT;
  keyStrokes: {
    [key: string]: boolean;
  };
  DEBUG?: DebugT;
}

const HotKeyHandler = ({
  children,
  keyStrokes,
  keyCode,
  instance,
  onKeyDown,
  conflictingKeys,
  eventHistory,
  onKeyUp,
  onKeyPress,
  register,
  unregister,
  DEBUG,
}: PropsT) => {
  React.useEffect(() => {
    const avoid = () =>
      conflictingKeys &&
      eventHistory &&
      conflictingKeys.some((s) =>
        eventHistory.stack.filter((value) => value !== keyCode).includes(s),
      );
    const downHandler: HandlerT = (...args) => {
      if (avoid()) return;
      onKeyDown?.(...args);
    };
    const upHandler: HandlerT = (...args) => {
      if (avoid()) return;
      onKeyUp?.(...args);
    };
    const pressHandler: HandlerT = (...args) => {
      if (avoid()) return;
      onKeyPress?.(...args);
    };
    if (!validateKey(keyCode, keyStrokes, instance)) return;
    onKeyDown && register(keyCode, downHandler, 'keydown');
    onKeyUp && register(keyCode, upHandler, 'keyup');
    onKeyPress && register(keyCode, pressHandler, 'keypress');
    if (DEBUG) {
      DEBUG.onRegister && DEBUG.onRegister(keyCode);
    }
    return () => {
      onKeyDown && unregister(keyCode, downHandler, 'keydown');
      onKeyUp && unregister(keyCode, upHandler, 'keyup');
      onKeyPress && unregister(keyCode, pressHandler, 'keypress');
      if (DEBUG) {
        DEBUG.onUnRegister && DEBUG.onUnRegister(keyCode);
      }
    };
  }, [
    keyCode,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    register,
    unregister,
    DEBUG,
    keyStrokes,
    instance,
    conflictingKeys,
    eventHistory,
  ]);
  return children;
};

interface ConsumerPropsT {
  children?: React.ReactNode;
  keyCode: string;
  onKeyDown?: HandlerT | undefined;
  onKeyUp?: HandlerT;
  onKeyPress?: HandlerT;
  /**
   * If you have declared key hotkeys in your app that can conflict with eachother (for example `c+meta` and `c`)
   * your can declare them in this prop and the handler will look into the history and make sure that the
   * conflicting hotkey was not triggered in the moments before the actual hotkey.
   */
  conflictingKeys?: Array<DefaultValidKeyCodesT> | undefined;
  DEBUG?: DebugT;
}

const Consumer = ({ conflictingKeys, ...rest }: ConsumerPropsT) => {
  return (
    <HotkeyContext.Consumer>
      {({
        register,
        eventHistory,
        unregister,
        keyStrokes,
        instance,
      }: ContextT) => {
        return (
          <HotKeyHandler
            unregister={unregister}
            register={register}
            eventHistory={eventHistory}
            keyStrokes={keyStrokes}
            instance={instance}
            conflictingKeys={conflictingKeys}
            {...rest}
          />
        );
      }}
    </HotkeyContext.Consumer>
  );
};

export default Consumer;
