import React from 'react';
import { useTranslations, withMentimeterI18nProvider } from '@mentimeter/i18n';
import type { VisitorToken } from '@mentimeter/splitio';
import type { UtmFirst, UtmLast } from '@mentimeter/utm';
import type AuthPackageT from '../../AuthPackage';
import type { AuthResponseT, OnErrorFnT } from '../../types';
import {
  FacebookTransparentButton,
  GoogleButton,
  MicrosoftButton,
} from './SocialButton';

export const SocialAuthWrapper = withMentimeterI18nProvider(
  ({
    visitorToken,
    onError,
    setLoading,
    onSuccess,
    utmFirst,
    utmLast,
    disabled,
    referral,
    isSignup,
    useRedirect,
    authPackage,
  }: {
    visitorToken: VisitorToken;
    onError: OnErrorFnT;
    setLoading: (isLoading: boolean) => void;
    onSuccess: (arg0: AuthResponseT) => any;
    utmFirst: UtmFirst | undefined;
    utmLast: UtmLast | undefined;
    useRedirect: boolean;
    referral: string;
    disabled: boolean;
    isSignup: boolean;
    authPackage: AuthPackageT;
  }) => {
    const t = useTranslations('authPackage');
    const {
      components: { SocialAuth },
    } = authPackage;

    return (
      <>
        <SocialAuth
          visitorToken={visitorToken}
          network="google"
          onError={onError}
          setLoading={setLoading}
          onSuccess={onSuccess}
          referral={referral}
          utmFirst={utmFirst}
          utmLast={utmLast}
          useRedirect={useRedirect}
          render={({ handleClick }) => (
            <GoogleButton onClick={handleClick} mb={2} disabled={disabled}>
              {isSignup ? t('signup_google') : t('login_google')}
            </GoogleButton>
          )}
        />

        <SocialAuth
          visitorToken={visitorToken}
          network="windows"
          onError={onError}
          setLoading={setLoading}
          onSuccess={onSuccess}
          referral={referral}
          utmFirst={utmFirst}
          utmLast={utmLast}
          useRedirect={Boolean(useRedirect)}
          render={({ handleClick }) => (
            <MicrosoftButton onClick={handleClick} mb={2} disabled={disabled}>
              {isSignup ? t('signup_microsoft') : t('login_microsoft')}
            </MicrosoftButton>
          )}
        />

        <SocialAuth
          visitorToken={visitorToken}
          network="facebook"
          onError={onError}
          setLoading={setLoading}
          onSuccess={onSuccess}
          referral={referral}
          utmFirst={utmFirst}
          utmLast={utmLast}
          useRedirect={useRedirect}
          render={({ handleClick }) => (
            <FacebookTransparentButton
              onClick={handleClick}
              mb={2}
              disabled={disabled}
            >
              {isSignup ? t('signup_facebook') : t('login_facebook')}
            </FacebookTransparentButton>
          )}
        />
      </>
    );
  },
);
