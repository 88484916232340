import type { VisitorToken } from '@mentimeter/splitio';
import type { ComponentType as ReactComponentT } from 'react';
import type { UtmTagsT } from '@mentimeter/utm';
import type {
  AllAuthClientT,
  AuthComponentBasePropsT,
  SocialAuthProvider,
} from '../types';

export interface PropsT extends AuthComponentBasePropsT, UtmTagsT {
  render: (arg0: { handleClick: () => any }) => any;
  network: SocialAuthProvider;
  useRedirect?: boolean;
  setLoading: (isLoading: boolean) => void;
  referral: string;
  visitorToken: VisitorToken;
}

function createSocialAuth(authClient: AllAuthClientT): ReactComponentT<PropsT> {
  const SocialAuth = ({
    onSuccess,
    onError,
    network,
    render,
    setLoading,
    useRedirect,
    utmFirst,
    utmLast,
    referral,
    visitorToken,
  }: PropsT) => {
    const onClick = () => {
      setLoading(true);
      authClient
        .authWithSocial({
          network,
          useRedirect,
          referral,
          utmFirst,
          utmLast,
          visitorToken,
        })
        .then(onSuccess, onError);
    };
    return render({ handleClick: onClick });
  };

  return SocialAuth;
}

export default createSocialAuth;
