import * as Yup from 'yup';
import { emojiRegex } from '@mentimeter/emojis';

export const createEmailValidation = <T extends string>(
  t: (value: T) => string,
) => ({
  email: Yup.string()
    .trim()
    .min(2, t('form_email_validation' as T))
    .email(t('form_email_validation' as T))
    .required(t('form_required' as T)),
});

export const PASSWORD_MAX_LENGTH = 50;
export const createPasswordValidation = <T extends string>(
  t: (stringId: T, options?: { passwordMinLength: number }) => string,
  passwordMinLength: number,
) => {
  return {
    password: Yup.string()
      .min(
        passwordMinLength,
        t('form_password_hint' as T, { passwordMinLength }),
      )
      .max(PASSWORD_MAX_LENGTH, t('form_password_validation_long' as T))
      .required(t('form_required' as T)),
  };
};

export const USERNAME_MAX_LENGTH = 50;
export const createNameValidation = (t: (value: string) => string) => ({
  name: Yup.string()
    .min(2, t('form_name_validation_short'))
    .required(t('form_required'))
    .test('Emoji validation', t('form_name_validation_emoji'), (value) =>
      Boolean(value && !emojiRegex.test(value)),
    )
    .test(
      'Special symbols',
      t('form_name_validation_punctuation_marks'),
      (value) => !['@', '[', ']', '{', '}'].some((s) => value?.includes(s)), // these symbols should not exist in a name
    ),
});
