import * as React from 'react';
import { Button, type ButtonT } from '@mentimeter/ragnar-ui';
import type { IconT } from '@mentimeter/ragnar-visuals';
import {
  FacebookIcon,
  Icon,
  FacebookIconBlue,
} from '@mentimeter/ragnar-visuals';

export const FacebookButton = (props: React.PropsWithChildren<ButtonT>) => {
  const { children } = props;

  return (
    <Button
      {...props}
      data-testid="facebook-auth-btn"
      iconLeading={FacebookIcon}
      extend={() => ({
        backgroundColor: '#4267b2',
        borderColor: '#4267b2',
        color: '#fff',
        ':hover': {
          backgroundColor: '#2f4e8f',
          color: '#fff',
        },
      })}
    >
      {children}
    </Button>
  );
};

export const FacebookTransparentButton = (
  props: React.PropsWithChildren<ButtonT>,
) => {
  const { children } = props;

  return (
    <Button
      {...props}
      data-testid="facebook-auth-btn"
      iconLeading={FacebookIconBlue}
      variant="outline"
    >
      {children}
    </Button>
  );
};

export const GoogleColoredIcon = (props: IconT) => (
  <Icon viewBox="0 0 48 48" {...props}>
    <defs>
      <path
        id="a"
        d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
      />
    </defs>
    <clipPath id="b">
      <use overflow="visible" xlinkHref="#a" />
    </clipPath>
    <path fill="#FBBC05" d="M0 37V11l17 13z" clipPath="url(#b)" />
    <path fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" clipPath="url(#b)" />
    <path fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" clipPath="url(#b)" />
    <path fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" clipPath="url(#b)" />
  </Icon>
);

export const GoogleButton = (props: React.PropsWithChildren<ButtonT>) => {
  const { children } = props;
  return (
    <Button
      {...props}
      data-testid="google-auth-btn"
      iconLeading={GoogleColoredIcon}
      variant="outline"
    >
      {children}
    </Button>
  );
};

export const MicrosoftColoredIcon = (props: IconT) => (
  <Icon viewBox="0 0 48 48" {...props}>
    <rect fill="#FFFFFF" x="0" y="0" width="100" height="100" />
    <rect fill="#05A5F0" x="3" y="25" width="20" height="20" />
    <rect fill="#FFBA06" x="25" y="25" width="20" height="20" />
    <rect fill="#F35424" x="3" y="3" width="20" height="20" />
    <rect fill="#81BC08" x="25" y="3" width="20" height="20" />
  </Icon>
);

export const MicrosoftButton = (props: React.PropsWithChildren<ButtonT>) => {
  const { children } = props;
  return (
    <Button
      {...props}
      data-testid="ms-auth-btn"
      iconLeading={MicrosoftColoredIcon}
      variant="outline"
    >
      {children}
    </Button>
  );
};
