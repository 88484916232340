import { gtmTrack, TrackingMethods } from '@mentimeter/google-tracking';

export const trackSocialAuth = (network: string) => {
  gtmTrack({
    event: 'socialAuth',
    type: network,
  });
  return Promise.resolve();
};

const fbPixelTrack = () => {
  try {
    (window as any).fbq('track', 'CompleteRegistration', {
      value: 0,
      currency: 'USD',
    });
    return Promise.resolve();
  } catch (_e) {
    return Promise.resolve();
  }
};

export const trackSignup = (referral?: string, email?: string) => {
  return Promise.all([
    TrackingMethods.trackSignup(referral, email),
    fbPixelTrack(),
  ]);
};
