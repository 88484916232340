const regex = /INPUT|TEXTAREA|SELECT/;

const draggableDivIsFocused = (
  e: React.KeyboardEvent<HTMLElement>,
): boolean => {
  const target = e.target as HTMLElement;
  if (target?.tagName) {
    const { tagName } = target;
    return (
      tagName.toUpperCase() === 'DIV' &&
      target.hasAttribute('data-rbd-drag-handle-draggable-id')
    );
  }
  return false;
};

const inputIsFocused = (e: React.SyntheticEvent<HTMLElement>): boolean => {
  const target = e.target as HTMLElement;
  if (target?.tagName) {
    const { tagName } = target;
    const isFocusableElement = tagName ? regex.test(tagName) : false;
    const isEditable = target.contentEditable === 'true';
    return isFocusableElement || isEditable;
  }
  return false;
};

const ignoreHotkeyModifier = (
  e: React.SyntheticEvent<HTMLElement>,
): boolean => {
  const target = e.target as HTMLElement;
  if (target?.dataset) {
    const { ignoreHotkeyWhenFocused } = target.dataset;
    return ignoreHotkeyWhenFocused === 'true';
  }
  return false;
};

const focusInModal = (e: React.SyntheticEvent<HTMLElement>): boolean => {
  const target = e.target as HTMLElement;
  return Boolean(target.closest('[role="dialog"]'));
};

export const filter = {
  inputIsFocused,
  draggableDivIsFocused,
  ignoreHotkeyModifier,
  focusInModal,
};
