import * as React from 'react';
import { Box, Text, type BoxT } from '@mentimeter/ragnar-ui';
import { withTheme } from '@mentimeter/ragnar-react';
import type { ThemeProps } from '@mentimeter/ragnar-react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';

export type MessageSeverity = 'negative' | 'info' | 'positive';

const severityToVariantMap: { [key in MessageSeverity]: string } = {
  info: 'bg-primary-weakest',
  negative: 'bg-negative-weakest',
  positive: 'bg-positive-weakest',
};

const severityToColor = (severity: MessageSeverity): string =>
  severityToVariantMap[severity];

export interface MessageT extends BoxT {
  severity: MessageSeverity;
}

export const Message = withTheme<MessageT & ThemeProps>(
  ({ children, severity, theme, className, ...rest }) => {
    if (!children) return null;

    const defaultClasses = [
      'p-4',
      'w-full',
      'max-w-full',
      'rounded-lg',
      'items-center',
    ];

    return (
      <Box
        className={clsx(defaultClasses, severityToColor(severity), className)}
        {...rest}
      >
        <Text className="text">{children}</Text>
      </Box>
    );
  },
);
