import * as React from 'react';
import type { ComponentType as ReactComponentT } from 'react';
import type { FormikHelpers } from 'formik';
import type { VisitorToken } from '@mentimeter/splitio';
import type { UtmTagsT } from '@mentimeter/utm';
import type {
  AllAuthClientT,
  AuthComponentBasePropsT,
  SignupModeInfoT,
} from '../types';
import { isValidEmail } from '../utils/isValidEmail';

interface SubmitValuesT {
  email: string;
  password: string;
  name: string;
}

type handleSubmitT = (
  values: SubmitValuesT,
  formikActions: FormikHelpers<SubmitValuesT>,
) => any;

interface RenderPropsT {
  handleSubmit: handleSubmitT;
  handleEmailBlur: React.FocusEventHandler<HTMLInputElement>;
  handleEmailChange: React.ChangeEventHandler<HTMLInputElement>;
  signupModeInfo: SignupModeInfoT;
}

export interface PropsT extends AuthComponentBasePropsT, UtmTagsT {
  referral: string;
  render: (arg0: RenderPropsT) => any;
  setLoading: (isLoading: boolean) => void;
  visitorToken: VisitorToken;
  inviteToken?: string | undefined;
}

export interface StateT {
  signupModeInfo: SignupModeInfoT;
}

const initialSignupModeInfo: SignupModeInfoT = {
  companyName: '',
  id: undefined,
  signupMode: 'no_sso',
  joinUrl: '',
  samlLoginUrl: '',
};

function createSignup(authClient: AllAuthClientT): ReactComponentT<PropsT> {
  class Signup extends React.Component<PropsT, StateT> {
    override state = {
      signupModeInfo: initialSignupModeInfo,
    };
    override render() {
      const {
        render,
        referral,
        utmFirst,
        utmLast,
        setLoading,
        onSuccess,
        onError,
        visitorToken,
        inviteToken,
      } = this.props;
      const { signupModeInfo } = this.state;
      return render({
        handleSubmit: ({ email, password, name }, { setSubmitting }) => {
          const searchParams = new URL(window.location.href).searchParams;
          const workspaceUrl = searchParams.get('workspace_url') || undefined;
          const signupParams = {
            email,
            password,
            name,
            referral,
            utmFirst,
            utmLast,
            visitorToken,
            workspaceUrl,
            inviteToken,
          };
          setLoading(true);
          authClient
            .signup(signupParams)
            .then((res) => {
              setSubmitting(false);
              onSuccess(res);
            })
            .catch((e) => {
              setSubmitting(false);
              onError(e);
            });
        },
        handleEmailBlur: ({ currentTarget: { value } }) => {
          if (!value || value === '' || !isValidEmail(value)) return;

          authClient
            .checkSignupMode(value)
            .then(
              ({
                companyName,
                id,
                signupMode,
                joinUrl,
                samlLoginUrl,
              }: SignupModeInfoT) => {
                this.setState({
                  signupModeInfo: {
                    companyName: companyName || '',
                    id,
                    signupMode,
                    joinUrl,
                    samlLoginUrl,
                  },
                });
              },
            )
            .catch(() => {
              // Handled by checkSignupMode
            });
        },
        handleEmailChange: () => {
          if (signupModeInfo.joinUrl?.length > 1) {
            this.setState({ signupModeInfo: initialSignupModeInfo });
          }
        },
        signupModeInfo,
      });
    }
  }

  return Signup;
}

export default createSignup;
