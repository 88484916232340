'use client';

import type { WwwExperiments } from './split-experiments';
import { experiments } from './split-experiments';
import { createSplitEnvironment } from './create-splitio-environment';
import { getDefaultUserAttributes } from './get-default-user-attributes';
import { getDeviceAttributes } from './get-device-attributes';

function getAttributes() {
  return {
    ...getDefaultUserAttributes(),
    ...getDeviceAttributes(),
    devEnv: Boolean(process.env['NODE_ENV'] !== 'production'),
  };
}

const { useSplits, useSplitsLazy, SplitProvider } =
  createSplitEnvironment<WwwExperiments>(experiments, { getAttributes });

export { useSplits, useSplitsLazy, SplitProvider };
