import { LocalStorage } from '@mentimeter/storage';
import type {
  SocialAuthClientConfigT,
  HelloAuthResponseT,
  HelloLoginResponseT,
  HelloMeLoginResponseT,
  SocialAuthProvider,
  SocialUserT,
} from '../types';
import { ClientAuthError, ErrorCodes } from './userApi/errors';
const hello = typeof window === 'undefined' ? {} : require('hellojs');

const createSocialAuthClient = ({ helloOptions }: SocialAuthClientConfigT) => {
  if (typeof window === 'undefined') return;
  const helloSdk: any = hello;

  helloSdk.init(helloOptions, { scope: 'email' });

  function getTokenFromAuthResponse(
    authResponse: HelloAuthResponseT,
  ): string | null {
    const currentTime = new Date().getTime() / 1000;
    const isLoggedIn = Boolean(
      authResponse &&
        authResponse.access_token &&
        authResponse.expires > currentTime,
    );
    return isLoggedIn ? authResponse.access_token : null;
  }

  function tryGetToken(
    network: SocialAuthProvider,
    useRedirect: boolean,
  ): string | null {
    // Never auto-login with redirect
    if (useRedirect) return null;

    const authResponse: HelloAuthResponseT =
      helloSdk(network).getAuthResponse();
    return getTokenFromAuthResponse(authResponse);
  }

  function getMyUser(
    network: SocialAuthProvider,
  ): Promise<HelloMeLoginResponseT> {
    return helloSdk(network).api('me');
  }

  function promptLoginAndGetToken(
    network: SocialAuthProvider,
    useRedirect: boolean,
  ): Promise<string> {
    const redirectUri = window.location.origin || '';

    const baseOptions =
      network === 'google'
        ? { redirect_uri: redirectUri, scope: 'email,profile' }
        : { redirect_uri: redirectUri };

    const options = useRedirect
      ? { ...baseOptions, display: 'page' }
      : baseOptions;

    return helloSdk
      .login(network, options)
      .then(({ authResponse }: HelloLoginResponseT) => {
        const token = getTokenFromAuthResponse(authResponse);
        return Promise.resolve(token);
      });
  }

  return {
    getSocialSignInState: () => {
      const networks = Object.keys(helloOptions) as SocialAuthProvider[];
      return (
        networks.find((network) => {
          const data = helloSdk(network).getAuthResponse();
          return data !== null;
        }) ?? null
      );
    },
    socialSignOut: (network: SocialAuthProvider) => {
      helloSdk.logout(network);
    },
    authAndGetSocialUser: (
      network: SocialAuthProvider,
      useRedirect = false,
    ): Promise<SocialUserT> => {
      if (!LocalStorage.isSupported()) {
        return Promise.reject(
          new ClientAuthError(ErrorCodes.LOCAL_STORAGE, 'social'),
        );
      }
      const initialToken = tryGetToken(network, useRedirect);
      // 1. first we get token either by checking existing local token or by logging in
      return (
        initialToken
          ? Promise.resolve(initialToken)
          : promptLoginAndGetToken(network, useRedirect)
      )
        .then((token) => {
          // then we fetch the user, hello js already uses our our accesstoken for this
          return getMyUser(network).then(
            (user) => {
              return Promise.resolve({ validToken: token, socialUser: user });
            },
            (_err) => {
              // catching errors from getMyUser. Our token is probably not valid
              // we promt user to login so we can assure to have a valid token
              // this case normally happens when a user logs out from facebook but hellojs localtoken is still there
              return promptLoginAndGetToken(network, useRedirect).then(
                (token) => {
                  return getMyUser(network).then((user) => {
                    return Promise.resolve({
                      validToken: token,
                      socialUser: user,
                    });
                  });
                },
              );
            },
          );
        })
        .then(
          ({ validToken, socialUser }) => {
            return Promise.resolve({
              ...socialUser,
              accessToken: validToken,
              network,
            });
          },
          () => {
            // Hide social auth errors
            return Promise.reject(
              new ClientAuthError(ErrorCodes.UNKNOWN, 'social'),
            );
          },
        );
    },
  };
};

export default createSocialAuthClient;
