import * as React from 'react';
import type { TrackingPayload } from '@mentimeter/http-clients';
import type { SSOInfoT, SSOType } from '../../types';
import type AuthPackageT from '../../AuthPackage';
import { getCurrentInputVal } from '../../utils/getCurrentInputVal';
import LogInUI from './LogInUI';
import SignUpWrapper from './SignUpWrapper';

type RenderAuthProps = Record<string, unknown>;

interface RenderPropsT {
  toggleAuthMode: React.MouseEventHandler;
  SSOMessageActive: boolean;
  showSignup: boolean;
  renderAuth: (componentProps?: RenderAuthProps) => React.ReactNode;
}

interface PropsT {
  showSignup: boolean;
  authPackage: AuthPackageT;
  render: (props: RenderPropsT) => React.ReactNode;
  continueUrl?: string | undefined;
  referral: string;
  useSocialRedirect?: boolean;
  preFilledEmail?: string | null;
  emailChangeDisabled?: boolean;
  showEmailAutoSuggest?: boolean | undefined;
  SignupComponent?: typeof SignUpWrapper;
  LoginUIComponent?: typeof LogInUI;
  track?: undefined | ((payload: TrackingPayload) => void);
  inviteToken?: string;
}

interface StateT {
  showSignup: boolean;
  SSOType: SSOType;
  initialName: string;
  initialPassword: string;
  initialEmail: string;
}

class AuthSwitch extends React.Component<PropsT, StateT> {
  override state: StateT = {
    showSignup: this.props.showSignup,
    SSOType: 'none',
    initialName: '',
    initialEmail: this.props.preFilledEmail || '',
    initialPassword: '',
  };
  onSSOChanged = (SSOInfo: SSOInfoT) => {
    const { type } = SSOInfo;
    this.setState({ SSOType: type });
  };
  toggleAuthMode = (e: React.MouseEvent) => {
    e.preventDefault();
    const email = getCurrentInputVal('.authWrapper', e, 'email');
    const password = getCurrentInputVal('.authWrapper', e, 'password');
    const { showSignup } = this.state;
    let newState: Partial<StateT> = {
      showSignup: !showSignup,
      initialEmail: email,
      initialPassword: password,
    };
    if (showSignup) {
      const name = getCurrentInputVal('.authWrapper', e, 'name');
      newState = { ...newState, initialName: name };
    }
    this.setState({ ...this.state, ...newState });
  };

  renderAuth = (componentProps?: RenderAuthProps) => {
    const { showSignup, initialEmail, initialName, initialPassword } =
      this.state;
    const {
      authPackage,
      continueUrl,
      referral,
      useSocialRedirect,
      emailChangeDisabled,
      track,
      showEmailAutoSuggest,
      SignupComponent = SignUpWrapper,
      LoginUIComponent = LogInUI,
      inviteToken,
    } = this.props;

    return showSignup ? (
      <SignupComponent
        authPackage={authPackage}
        SSOChanged={this.onSSOChanged}
        initialEmail={initialEmail}
        initialName={initialName}
        initialPassword={initialPassword}
        continueUrl={continueUrl}
        referral={referral}
        useSocialRedirect={useSocialRedirect}
        emailChangeDisabled={emailChangeDisabled}
        track={track}
        showEmailAutoSuggest={showEmailAutoSuggest}
        inviteToken={inviteToken}
        {...componentProps}
      />
    ) : (
      <LoginUIComponent
        referral={referral}
        authPackage={authPackage}
        initialEmail={initialEmail}
        initialPassword={initialPassword}
        continueUrl={continueUrl}
        useSocialRedirect={useSocialRedirect}
        emailChangeDisabled={emailChangeDisabled}
        {...componentProps}
      />
    );
  };

  override render() {
    const { render } = this.props;
    const { SSOType, showSignup } = this.state;
    const SSOMessageActive = SSOType === 'strict';
    return render({
      toggleAuthMode: this.toggleAuthMode,
      SSOMessageActive,
      renderAuth: this.renderAuth,
      showSignup,
    });
  }
}

export default AuthSwitch;
