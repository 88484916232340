import { fetchRegionErrors } from '@mentimeter/region';
import { capitalize } from '../../utils/capitalize';

export const ErrorCodes = {
  EXPOSED_CREDENTIALS: 'exposed_credentials',
  PASSWORD_INVALID_LENGTH: 'password_invalid_length',
  PASSWORD_ATTEMPT_DENIED: 'password_attempt_denied',
  INVALID_CREDENTIALS: 'invalid_credentials',
  SOCIAL_LOGIN_ENABLED: 'social_login_enabled',
  SAML_LOGIN_ENABLED: 'saml_login_enabled',
  SUSPENDED_USER: 'suspended_user',
  NO_USER_FOUND: 'no_user_found',
  NOT_FOUND: 'not_found',
  RESTRICTED_DOMAIN: 'restricted_domain',
  NOT_UNIQUE_MSG: 'Email is already taken',
  EMAIL_NOT_UNIQUE: 'email_not_unique',
  NOT_REGISTERED_EMAIL: 'not_registered_email',
  SAML_CANNOT_CHANGE_PASSWORD: 'saml_cannot_change_password',
  CANNOT_CHANGE_PASSWORD: 'cannot_change_password',
  SOCIAL_EMAIL_PERMISSION_MISSING: 'social_email_permissions_missing',
  IN_DATA_TRANSFER: 'in_data_transfer',
  LOCAL_STORAGE: 'local_storage',
  SERVICE_UNAVAILABLE: 'service_unavailable',
  UNKNOWN: 'unknown',
} as const;

type ErrorCode = (typeof ErrorCodes)[keyof typeof ErrorCodes];

type AuthType = 'social' | 'login' | 'signup';

export const extractErrorMessage = (err: ServerAuthError | ClientAuthError) => {
  if (!(err instanceof ServerAuthError)) return;
  return err.originalError.response?.data?.message;
};

export const extractMetaData = (err: ServerAuthError | ClientAuthError) => {
  if (!(err instanceof ServerAuthError)) return;

  const data = err.originalError.response?.data;
  if (!data) return;

  return {
    loginUrl: data.login_url || '',
    companyName: capitalize(data.workspace_name || ''),
    companyContactEmail: data.contact_email || '',
    companyContactUrl: data.contact_url || '',
    companyContactName: data.contact_name || '',
  };
};

const EMAIL_NOT_UNIQUE_MSG = 'Email is already taken';
const isEmailNotUniqueMessage = (errors: any): boolean => {
  if (!errors) return false;
  const [firstErrorArr] = Object.values(errors);

  return (
    Boolean(firstErrorArr) &&
    Array.isArray(firstErrorArr) &&
    firstErrorArr[0] === EMAIL_NOT_UNIQUE_MSG
  );
};

const extractCode = (error: any) => {
  if (error.message === fetchRegionErrors.TRANSFER_IN_PROGRESS) {
    return ErrorCodes.IN_DATA_TRANSFER;
  } else if (isEmailNotUniqueMessage(error)) {
    return ErrorCodes.EMAIL_NOT_UNIQUE;
  } else if (error.status === 503) {
    return ErrorCodes.SERVICE_UNAVAILABLE;
  }
  return error.response?.data?.code ?? ErrorCodes.UNKNOWN;
};

export class ServerAuthError extends Error {
  type: AuthType;
  code: ErrorCode;
  originalError: any;

  constructor(originalError: any, type: AuthType) {
    const code = extractCode(originalError);

    super(code);
    this.originalError = originalError;
    this.code = code;
    this.type = type;
  }
}

export class ClientAuthError extends Error {
  type: AuthType;
  code: ErrorCode;

  constructor(code: ErrorCode, type: AuthType) {
    super(code);
    this.code = code;
    this.type = type;
  }
}
